<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import infrastructureTable from './components/infrastructureTable';
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";
import {required,decimal,maxLength,requiredIf} from "vuelidate/lib/validators";
// import func from 'vue-editor-bridge';
export default {
  page: {
    title: "Gestion Des Infrastructures",
    meta: [{ name: "description", content: appConfig.description }]
  },
  import: {getDropDownElementsByTypeApi},
  components: { Layout, PageHeader , infrastructureTable, Multiselect},
  data() {
    return {
      reloadInfrastructure: false,
      tableData: [],
      selectedBlocType: [],
      selectedUnitType :[],
      disabled : true,
      canDelte : true,
      disabledAdd : false,
      disabledUpdate : false,
      disabledCancel : true,
      title: "Gestion Des Infrastructures",
      blocs: [],
      blocTyps :[{ id:'VIP',name:'VIP'}, {id:'Ordinnaire',name:'Ordinnaire'},{id:'Missionnaire',name:'Missionnaire'},
                { id:'Stagiaire',name:'Stagiaire'} , {id:'LUXE',name:'LUXE'} , {id:'Administratif',name:'Administratif'} ,{id:'Autre',name:'Autre'} ],
      unitTyps :[],
      lifebases : [],
      lastSelect : undefined,
      site_ids : false,
      zone_ids : false,
      bloc_ids : false,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Gestion Des Infrastructures",
          active: true
        }
      ],
      form: {
        life_base_id : '',
        region :{id:'', name : '', description : ''},
        site :{id:'', name : '', description : ''},
        zone :{id:'', name : '', description : ''},
        bloc :{id:'', name : '', description : '',type :''},
        unit :{id:'', name : '', description : '',type :''},
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ],
      
      submitted: false,
     
    };
  },
  validations: {
    form: {
      life_base_id : {
        required
      },
      region : {
        name : {
          required,
          maxLength: maxLength(64),
        },
        description :{
          
          maxLength : maxLength(190)
        }
      },
      site : {
        name : {
          required: requiredIf(function() {
              return this.form.unit.name || this.form.bloc.name || this.form.zone.name
          }),
          maxLength: maxLength(64),
        },
        description :{
          
          maxLength : maxLength(190)
        }
      },
      zone : {
        name : {
          required: requiredIf(function() {
            return this.form.unit.name  || this.form.bloc.name
          }),
          maxLength: maxLength(64),
        },
        description :{
          
          maxLength : maxLength(190)
        }
      },
      bloc : {
        name : {
          required: requiredIf(function() {
            return this.form.unit.name
          }),
          maxLength: maxLength(64),
        },
        description :{
          maxLength : maxLength(190)
        },
        type :{
          required : requiredIf(function() {
            return  this.form.bloc.name
          }),
        }
      },
      unit : {
        name : {
          maxLength: maxLength(64),
        },
        description :{
          maxLength : maxLength(190)
        },
        type :{
          required : requiredIf(function() {
            return this.form.unit.name
          }),
        }
      },
    },
  },
  created() {
    this.fetchUnitTypesList();
    this.getLifeBases();
  },
  methods: {
    fetchUnitTypesList(){
      getDropDownElementsByTypeApi('unit_type')
      .then(res => (this.unitTyps = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.clearForms();
      this.canDelte       = true;
      this.disabledCancel = false;
    },
    duplicateBtn(){
      this.disabled         = false;
      this.disabledAdd      = true;
      this.disabledUpdate   = false;
      this.canDelte         = true;
      this.form.region.uuid = "";
      this.form.site.uuid   = "";
      this.form.zone.uuid   = "";
      this.form.bloc.uuid   = "";
      this.form.unit.uuid   = "";
      this.disabledCancel   = false;
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
      this.disabledCancel = false;
      this.canDelte       = true;

    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.disabledCancel = true;
      this.canDelte       = false;
      this.selectedInfrastructure(this.lastSelect)
    },

    disabledAllBtn(){

    },
    getLifeBases(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.get('/base/lifebases/index')
      .then(response => {
          this.lifebases = response.data;
          loader.hide();
      })
      .catch(error => console.log(error))
    },

    /**
     * Search the table data with search input
     */

    refreshInfrastructureTable(){
      
    },

    addStr(){
      this.form.bloc.type = this.selectedBlocType ;
      this.form.unit.type = this.selectedUnitType ;
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http.post("/organisation/infrastructure/region/store", this.form)
          .then((res) => {
            var status = res.status;
            if(res.data.errors == 0 ){
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");

                this.reloadInfrastructure = true
                this.lastSelect = null;
                this.clearForms()
                this.disabledAdd = false;
                this.disabledUpdate = false;
                this.disabled = true;
                //      setTimeout(function(){
                //     this.reloadInfrastructure  = false;
                // },5000)
            }else{
                // Swal.fire("Avertissement!", res.data.msg, "warning");
                this.$toast.warning(res.data.msg);

            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },

    updateStr(){
      this.form.bloc.type = this.selectedBlocType ;
      this.form.unit.type = this.selectedUnitType ;
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitted = false
        let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
        this.$http.post("/organisation/infrastructure/region/update", this.form)
          .then((res) => {
            var status = res.status;
            if(!res.data.errors ){
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.reloadInfrastructure = true
                this.lastSelect = null;
                this.clearForms()
                this.disabledAdd = false;
                this.disabledUpdate = false;
                this.disabled = true;
                //     setTimeout(function(){
                //     this.reloadInfrastructure  = false;
                // },5000)
            }else{
                // Swal.fire("Avertissement!", res.data.msg, "warning");
                this.$toast.warning(res.data.msg);
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },

    removeRegion(){
      if(this.form.region.id == "" || this.form.region.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :  " + this.form.region.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/infrastructure/region/delete" ,{'code':this.form.region.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadInfrastructure = true
                this.clearForms()

                this.lastSelect = null;
                    setTimeout(function(){
                    this.reloadInfrastructure  = false;
                },2000)
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeSite(){
      if(this.form.site.id == "" || this.form.site.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :  " + this.form.site.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/infrastructure/site/delete" ,{'code':this.form.site.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                this.$toast.warning(data.msg);
                // Swal.fire("Erreur!", data.msg, "error")
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadInfrastructure = true
                this.clearForms()

                this.lastSelect = null;

                    setTimeout(function(){
                    this.reloadInfrastructure  = false;
                },2000)
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeZone(){
      if(this.form.zone.id == "" || this.form.zone.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :  " + this.form.zone.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/infrastructure/zone/delete" ,{'code':this.form.zone.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadInfrastructure = true
                this.clearForms()

                this.lastSelect = null;
                    setTimeout(function(){
                    this.reloadInfrastructure  = false;
                },2000)
                 this.form.zone.id = ""
                this.form.zone.name = "";
                this.form.zone.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeBloc(){
      if(this.form.bloc.id == "" || this.form.bloc.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :  " + this.form.bloc.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/infrastructure/building/delete" ,{'code':this.form.bloc.id})
            .then((response) => {
              var data = response.data;
              if(data.errors  != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                 this.reloadInfrastructure = true
                this.clearForms()

                this.lastSelect = null;
                    setTimeout(function(){
                    this.reloadInfrastructure  = false;
                },2000)
                this.form.bloc.id = ""
                this.form.bloc.name = "";
                this.form.bloc.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    removeUnit(){
      if(this.form.unit.id == "" || this.form.unit.id == null)
      { 
        Swal.fire("Erreur!","Aucun élément n'est sélectionné","error")
      }else
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :  " + this.form.unit.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => { 
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/organisation/infrastructure/unit/delete" ,{'code':this.form.unit.id})
            .then((response) => {
              var data = response.data;
              if(data.errors != 0){
                // Swal.fire("Erreur!", data.msg, "error")
                this.$toast.warning(data.msg);
              }else{
                // Swal.fire("Félicitations!", "l'opération a été effectué avec succès", "success");
                this.$toast.success("l'opération a été effectué avec succès");
                this.clearForms()
                 this.reloadInfrastructure = true
                this.lastSelect = null;
                    setTimeout(function(){
                    this.reloadInfrastructure  = false;
                },2000)
                this.form.unit.id = ""
                this.form.unit.name = "";
                this.form.unit.description = "";
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    },
    
    selectedInfrastructure: function(selectedRows){
      this.clearForms()
      this.canDelte       = true;

      if(selectedRows.length> 0 &&  undefined != selectedRows[0]){
        this.canDelte       = false;
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledUpdate = false;
        this.form.life_base_id = selectedRows[0]['region'].life_base_id
        this.form.region.id    = selectedRows[0]['region'].regionCode ; this.form.region.name   = selectedRows[0]['region'].regionName ; this.form.region.description   = selectedRows[0]['region'].regionDescription;
        this.form.site.id      = selectedRows[0]['site'].siteCode ; this.form.site.name         = selectedRows[0]['site'].siteName ; this.form.site.description         = selectedRows[0]['site'].siteDescription ;
        this.form.zone.id      = selectedRows[0]['zone'].zoneCode ; this.form.zone.name         = selectedRows[0]['zone'].zoneName ;  this.form.zone.description        = selectedRows[0]['zone'].zoneDescription ;
        this.form.bloc.id      = selectedRows[0]['building'].buildingCode ; this.form.bloc.name = selectedRows[0]['building'].buildingName ; this.form.bloc.description = selectedRows[0]['building'].buildingDescription ;
        this.form.unit.id      = selectedRows[0]['unit'].unitCode ; this.form.unit.name         = selectedRows[0]['unit'].unitName ; this.form.unit.description         = selectedRows[0]['unit'].unitDescription ;
        this.form.bloc.type   = selectedRows[0]['building'].buildingType
        this.selectedBlocType = this.blocTyps.filter(x => ( x.id == this.form.bloc.type) );
        this.form.unit.type   = selectedRows[0]['unit'].unitType
        this.selectedUnitType = this.unitTyps.filter(x => ( x.id == this.form.unit.type) );

         this.lastSelect       = selectedRows

        // this.blocTyps = selectedRows[0]['building'].blocType
        // this.unitTyps  = selectedRows[0]['building'].blocType
      }

    },
    clearForms(){
        this.disabledCancel = true;
        this.form.region.id = "" ; this.form.region.name  = "" ; this.form.region.description = "" ;
        this.form.site.id = "" ; this.form.site.name =  "" ; this.form.site.description = "" ;
        this.form.zone.id = '' ; this.form.zone.name = "" ;  this.form.zone.description = "" ;
        this.form.bloc.id = "" ; this.form.bloc.name = "" ; this.form.bloc.description = "" ; this.form.bloc.type = "";
        this.form.unit.id = "" ; this.form.unit.name =  "" ; this.form.unit.description =  "" ; this.form.unit.type = "";
        this.selectedBlocType = [];
        this.selectedUnitType = [];
        this.form.life_base_id =""
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <infrastructure-table :reloadInfrastructure="reloadInfrastructure" @selectedInfrastructure="selectedInfrastructure" @refreshInfrastructureDone="reloadInfrastructure = $event" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
              <ul id="StickyGeo">
                <li>
                  <button type="button" :disabled="disabledAdd" @click="addBtn" class="btn btn-addGeo rounded-pillGeo float-right mr-1 mt-1">
                  <i class="fas fa-plus text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="canDelte" @click="duplicateBtn" class="btn btn-duplicateGeo rounded-pillGeo float-right mr-1 mt-1">
                  <i class="far fa-copy text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="canDelte" @click="editBtn" class="btn btn-editGeo rounded-pillGeo float-right mr-1 mt-1">
                    <i class="fas fa-pencil-alt text-white" style=""></i>
                  </button>
                </li>
                <li>
                <b-dropdown id="dropdown-dropleft" :disabled="canDelte" pill class="btn-deleteGeo rounded-pillGeo float-right mr-1 mt-1" dropleft  variant="danger" style="border-radius: 50% !important">
                  <template #button-content  style="border-radius: 50% !important">
                     <i class="far fa-trash-alt text-white"></i>
                  </template>
                  <b-dropdown-item @click="removeRegion" href="javascript: void(0);">Region</b-dropdown-item>
                  <b-dropdown-item @click="removeSite" href="javascript: void(0);">Site</b-dropdown-item>
                  <b-dropdown-item @click="removeZone" href="javascript: void(0);">Zone</b-dropdown-item>
                  <b-dropdown-item @click="removeBloc" href="javascript: void(0);">Bloc</b-dropdown-item>
                  <b-dropdown-item @click="removeUnit" href="javascript: void(0);">Unit</b-dropdown-item>
                </b-dropdown>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-uploadGeo rounded-pillGeo float-right mr-1 mt-1">
                  <i class="fas fa-file-upload  text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-downloadGeo rounded-pillGeo float-right mr-1 mt-1">
                  <i class="fas fa-file-download text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-printGeo rounded-pillGeo float-right mr-1 mt-1">
                  <i class="fas fa-print"></i>
                  </button>
                </li>
              </ul>
              <h4 class="card-title  pb-2">
                    Infrastructure Details
                  
              </h4>
              <fieldset class="col-12" :disabled="disabled">
                <table class="table table-sm table-head-bg-brand mt-4">
                    <thead class="thead-inverse">
                      <tr class="bg-light ">
                        <th class="align-middle">Base de Vie</th>
                        <th colspan="6">
                            <select  :class="{'is-invalid': submitted && $v.form.life_base_id.$error,}"  class="form-control" required v-model="form.life_base_id">
                              <option disabled >Selectionner la base</option>
                              <option v-for="m in lifebases" v-bind:value="m.id" :key="m.id" >{{m.name}}</option>
                            </select>
                          <div v-if="submitted && $v.form.life_base_id.$error" class="invalid-feedback">
                            <span v-if="!$v.form.life_base_id.required">Ce champ est obligatoire.</span>
                          </div>
                        </th>
                      </tr>
                      <tr >
                        <th ></th>
                        <th  class="mb-2">Designation</th>
                        <th colspan="4" class="mb-2">Description</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                          <td scope="row" class="align-middle">Region <input v-model="form.region.id" class="form-control " type="hidden" ></td>
                          <td><input :class="{'is-invalid': submitted && $v.form.region.name.$error,}" v-model="form.region.name" class="form-control " type="text" >
                          <div v-if="submitted && $v.form.region.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.region.name.required">Ce champ est obligatoire.</span>
                            <br />
                            <span v-if="!$v.form.region.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.region.name.maxLength }}</b>Caractères.</span>
                          </div></td>
                          <td colspan="5"><input :class="{'is-invalid': submitted && $v.form.region.description.$error,}" v-model="form.region.description" class="form-control " type="text" ></td>
                       </tr>
                      <tr>
                          <td scope="row" class="align-middle">Site <input v-model="form.site.id" class="form-control " type="hidden" ></td>
                          <td><input :class="{'is-invalid': submitted && $v.form.site.name.$error,}" v-model="form.site.name" class="form-control " type="text" >
                          <div v-if="submitted && $v.form.site.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.site.name.required">Ce champ est obligatoire.</span>
                            <br />
                            <span v-if="!$v.form.site.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.site.name.maxLength }}</b>Caractères.</span>
                          </div></td>
                          <td colspan="5"><input :class="{'is-invalid': submitted && $v.form.site.description.$error,}"  v-model="form.site.description" class="form-control " type="text" ></td>
                           </tr>
                      <tr>
                          <td scope="row" class="align-middle">Zone <input v-model="form.zone.id" class="form-control " type="hidden" ></td>
                          <td><input :class="{'is-invalid': submitted && $v.form.zone.$error,}" v-model="form.zone.name" class="form-control " type="text"  >
                              <div v-if="submitted && $v.form.zone.name.$error" class="invalid-feedback">
                                <span v-if="!$v.form.zone.name.required">Ce champ est obligatoire.</span>
                                <br />
                                <span v-if="!$v.form.zone.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.zone.name.maxLength }}</b>Caractères.</span>
                            </div>
                          </td>
                          <td colspan="5"><input :class="{'is-invalid': submitted && $v.form.zone.description.$error,}" v-model="form.zone.description" class="form-control " type="text" ></td>
                       </tr>
                      <tr>
                      <td scope="row" class="align-middle">Bloc <input v-model="form.bloc.id" class="form-control " type="hidden" ></td>
                        <td><input :class="{'is-invalid': submitted && $v.form.bloc.name.$error,}" v-model="form.bloc.name" class="form-control " type="text" >
                          <div v-if="submitted && $v.form.bloc.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.bloc.name.required">Ce champ est obligatoire.</span>
                            <br />
                            <span v-if="!$v.form.bloc.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.bloc.name.maxLength }}</b>Caractères.</span>
                          </div>
                        </td>
                        <td colspan="4"><input :class="{'is-invalid': submitted && $v.form.bloc.description.$error,}" v-model="form.bloc.description" class="form-control " type="text"  ></td>
                        <td>
                          <multiselect
                            :class="{'is-invalid': submitted && $v.form.bloc.type.$error, 'disabledMS': disabled}" 
                            v-model="selectedBlocType"
                            :searchable="true"
                            track-by="id"
                            label="name"
                            :options="blocTyps"
                            placeholder="Selectionner le Type"
                            :allow-empty="false"
                            :select-label="''"
                            :deselect-label="''"
                          >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row" class="align-middle">Unite <input v-model="form.unit.id" class="form-control " type="hidden" ></td>
                        <td><input :class="{'is-invalid': submitted && $v.form.unit.name.$error,}" v-model="form.unit.name" class="form-control " type="text" >
                          <div v-if="submitted && $v.form.unit.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.unit.name.required">Ce champ est obligatoire.</span>
                            <br />
                            <span v-if="!$v.form.unit.name.maxLength">La taille minimum du champs est:<b>{{ this.vrules.unit.name.maxLength }}</b>Caractères.</span>
                          </div>
                        </td>
                        <td colspan="4"><input :class="{'is-invalid': submitted && $v.form.unit.description.$error,}" v-model="form.unit.description" class="form-control " type="text" ></td>
                        <td>
                          <multiselect
                            :class="{'is-invalid': submitted && $v.form.unit.type.$error,'disabledMS': disabled}"
                            v-model="selectedUnitType"
                            :searchable="true"
                            track-by="id"
                            label="designation"
                            :options="unitTyps"
                            placeholder="Selectionner le Type"
                            :allow-empty="false"
                            :select-label="''"
                            :deselect-label="''"
                          >
                            <template
                              slot="singleMealType"
                              slot-scope="{ type }"
                              >{{ type.designation }}</template
                            >
                          </multiselect>
                        </td>
                      </tr>
                    </tbody>
                </table>
                <div class="button-items float-right " >
                    <button type="button"  @click="cancelBtn" v-show="!disabled" class="btn btn-light btn-label float-right ml-2">
                      <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                    </button>
                    <button
                      class="btn btn-primary float-right btn-label"
                      type="button"
                      @click="addStr"
                      v-show="disabledAdd"
                    >
                      <i class="far fa-save label-icon "></i> Enregistrer
                    </button>
                    <button
                      class="btn btn-primary float-right btn-label"
                      type="button"
                      @click="updateStr"
                      v-show="disabledUpdate"
                    >
                      <i class="far fa-save label-icon "></i> Enregistrer
                    </button>
                  </div>
              </fieldset>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyGeo {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  
  .btn-cancelGEO{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .btn-editGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteGeo #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printGeo{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>
