<script>
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

export default {
  props:['reloadInfrastructure'],
  components: { Dataset,DatasetItem,/*DatasetInfo,*/DatasetPager, /* DatasetSearch, */ DatasetShow,},
  data() {
    return {
      List_infrastructure: [],
      title: "Infrastructure",
      rows:'',  
      active:false,
      hierarchies: undefined,
      selectedRows: null,
      items: [
        {
          text: "Master Data",
          href: "javascript:void(0)",
        },
        {
          text: "Infrastructure",
          active: true,
        },
      ],
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      filters: {
        regionName : { value: '', keys: ['regionName'] },
        siteName : { value: '', keys: ['siteName'] },
        zoneName : { value: '', keys: ['zoneName'] },
        buildingName : { value: '', keys: ['buildingName'] },
        unitName : { value: '', keys: ['unitName'] },
      },
      cols: [
         {
          name: "Region",
          field: "regionName",
          sort: ''
        },
        {
          name: "Site",
          field: "siteName",
          sort: ''
        },
        {
          name: "Zone",
          field: "zoneName",
          sort: ''
        },
        {
          name: "Bloc",
          field: "buildingName",
          sort: ''
        },
        {
          name: "Unite",
          field: "unitName",
          sort: ''
        },
      ],
      selectedIndex:-1,




    };
  },

  mounted() {
    this.refreshInfrastructure();
  },
 
 watch:{
      selectedRows(){
        if(this.selectedRows.length){
         this.$emit('selectedInfrastructure',this.selectedRows)
        }else{
          this.$emit('selectedInfrastructure',[])
        }
      },
      infrastructure:{
          handler(val){
            this.List_infrastructure.push({
                ID_Suplier:val.ID_Suplier,
                name:val.name,
                raison_social:val.raison_social,
                statut_entreprise:val.statut_entreprise,
                adress1:val.adress1,
           });
            
          }
          
          
      },
      reloadInfrastructure:{
        handler(val){
          if(val){
            this.refreshInfrastructure();
          }
          
        }
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;  this.$emit('selectedInfrastructure',[])}
      else {
        this.$emit('selectedInfrastructure',[row])
        this.selectedIndex = index;
      }
    },
    getList_infrastructure(){
      
    },
    refreshInfrastructure(){
      let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
     this.$http.get('/organisation/infrastructure/region/getRegions')
      .then(response => {
        this.List_infrastructure = [];
        this.bases               = response.data;
        this.hierarchies         = response.data.data
        this.List_infrastructure = response.data.data
        this.totalPages          = response.data.totalPages;
        loader.hide();
        this.$emit("refreshInfrastructureDone",false)
        this.selectedRows = []; this.selectedIndex = -1;  
        /* this.totalRows   = response.totalRecords
        this.perPage     = response */
      })
      .catch(error =>{
          loader.hide();
      } )
    },
    deleteInfrastructure(supName, supUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + supName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/infrastructure/delete/" + supUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  // _this.refreshTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }

};
</script>

<template>
 <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Infrastructure</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset v-slot="{ ds }" :ds-data="List_infrastructure" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-6">
                      <!-- <dataset-search ds-search-placeholder="Search..." /> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.region.regionName }}</td>
                                <td>{{ row.site.siteName }}</td>
                                <td>{{ row.zone.zoneName }}</td>
                                <td>{{ row.building.buildingName }}</td>
                                <td>{{ row.unit.unitName }}</td>   
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
                <!-- <v-table
                    class="table table-hover"
                    :data="List_infrastructure"
                    :currentPage.sync="currentPage"
                    :pageSize="5"
                    @totalPagesChanged="totalPages = $event"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Region</th>
                      <th>Site</th>
                      <th>Zone</th>
                      <th>Bloc</th>
                      <th>Unite</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                   <v-tr v-for="row in displayData" :key="row.rowID"  :row="row">
                      <td>{{ row.region.regionName }}</td>
                      <td>{{ row.site.siteName }}</td>
                      <td>{{ row.zone.zoneName }}</td>
                      <td>{{ row.building.buildingName }}</td>
                      <td>{{ row.unit.unitName }}</td>                      
                    </v-tr>
                  </tbody>
                </v-table>
                <smart-pagination
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                /> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

      
      </div>
    </div>

</template>